import store from '@/store';
import { Actions, Mutations, UserRoles } from '@/store/enums/StoreEnums';
import { RouteLocationNormalized } from 'vue-router';

const handlePageTitle = (to: RouteLocationNormalized): void => {
  const routeExceptions = ['Wildcard', 'Details', 'Verify', 'Edit Car'];

  let routeName = '';
  if (to.name) {
    routeName = to.name.toString();
  }

  const containsExceptions = routeExceptions.some(
    (name) => routeName.indexOf(name) >= 0
  );

  if (!containsExceptions) {
    document.title = routeName;
  }
};

export default (to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  handlePageTitle(to);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  if (to.matched.some((record) => record.meta.loginRequired)) {
    const user: {
      username: string;
      role: UserRoles;
    } = JSON.parse(localStorage.getItem('user') || '{}');
    if (user) {
      const roleArrayHierarchic = to.matched
        .filter((x) => x.meta.roles)
        .map((x) => x.meta.roles);
      if (roleArrayHierarchic.every((x) => x.includes(user.role))) {
        next();
      } else {
        next({ name: '404' });
      }
    } else {
      store.dispatch(Actions.LOGOUT);
      next({ name: 'sign-in', query: { nextUrl: to.fullPath } });
    }
  } else {
    next();
  }
};
