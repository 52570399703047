import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

import authGuards from '@/utils/auth.guards';
import { UserRoles } from '@/store/enums/StoreEnums';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/dashboard',
    component: () => import('@/layout/Layout.vue'),
    meta: { loginRequired: true },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: '/sales-report',
        name: 'Sales Report',
        component: () => import('@/views/report/SalesReport.vue'),
      },
      {
        path: '/refund-list',
        name: 'Refund List',
        component: () => import('@/views/report/RefundList.vue'),
      },
      {
        path: '/sales-report/:id',
        name: 'Sales Report View',
        component: () => import('@/views/report/ViewReport.vue'),
      },
      {
        path: '/store-report',
        name: 'Store Report',
        component: () => import('@/views/report/StoreReport.vue'),
      },
      {
        path: '/store-report/:storeId',
        name: 'Store Report View',
        component: () => import('@/views/report/SalesReport.vue'),
      },
      // order starts
      {
        path: '/order/overview',
        name: 'Order Overview',
        component: () => import('@/views/order/Overview.vue'),
      },
      {
        path: '/order/pending',
        name: 'Order Pending',
        component: () => import('@/views/order/Pending.vue'),
      },
      {
        path: '/order/accepted',
        name: 'Order Accepted',
        component: () => import('@/views/order/Accepted.vue'),
      },
      {
        path: '/order/on-service',
        name: 'Order On Service',
        component: () => import('@/views/order/OnService.vue'),
      },
      {
        path: '/order/returned',
        name: 'Order returned',
        component: () => import('@/views/order/Returned.vue'),
      },
      {
        path: '/order/completed',
        name: 'Order Completed',
        component: () => import('@/views/order/Completed.vue'),
      },
      {
        path: '/order/cancel-list',
        name: 'Cancel List',
        component: () => import('@/views/order/CancelList.vue'),
      },
      {
        path: '/order/canceled',
        name: 'Order Canceled',
        component: () => import('@/views/order/Canceled.vue'),
      },
      {
        path: '/order/:id',
        name: 'Order WildCard',
        component: () => import('@/views/order/OrderWildcard.vue'),
      },
      // customer account starts
      {
        path: '/customer-account/lists',
        name: 'Customer Account Lists',
        component: () => import('@/views/customer/CustomerLists.vue'),
      },
      {
        path: '/customer-account/lists/:id',
        name: 'Customer Account Details',
        component: () => import('@/views/customer/CustomerDetails.vue'),
      },
      {
        path: '/customer-account/verify',
        name: 'Customer Account Verification Lists',
        component: () => import('@/views/customer/CustomerVerify.vue'),
      },
      {
        path: '/customer-account/verify/:id',
        name: 'Customer Account Verify',
        component: () => import('@/views/customer/CustomerVerifyWildcard.vue'),
      },
      // partner account starts
      {
        path: '/partner-account/lists',
        name: 'Partner Account Lists',
        component: () => import('@/views/partner/PartnerLists.vue'),
      },
      {
        path: '/partner-account/lists/:id',
        name: 'Partner Account Details',
        component: () => import('@/views/partner/PartnerDetails.vue'),
      },
      {
        path: '/partner-account/verify',
        name: 'Partner Account Verification Lists',
        component: () => import('@/views/partner/PartnerVerify.vue'),
      },
      {
        path: '/partner-account/verify/:id',
        name: 'Partner Account Verify',
        component: () => import('@/views/partner/PartnerVerifyWildcard.vue'),
      },
      // partner car starts
      {
        path: '/partner-car/lists',
        name: 'Partner Car Lists',
        component: () => import('@/views/partner-car/PartnerCarLists.vue'),
      },
      {
        path: '/partner-car/lists/:id',
        name: 'Partner Car Details',
        component: () => import('@/views/partner-car/PartnerCarDetails.vue'),
      },
      {
        path: '/partner-car/verify',
        name: 'Partner Car Verification Lists',
        component: () => import('@/views/partner-car/PartnerCarVerify.vue'),
      },
      {
        path: '/partner-car/verify/:id',
        name: 'Partner Car Verify Wildcard',
        component: () =>
          import('@/views/partner-car/PartnerCarVerifyWildcard.vue'),
      },
      // car-function route group starts
      {
        path: '/car-function/car-lists',
        name: 'Car Lists',
        component: () => import('@/views/car-function/CarLists.vue'),
      },
      {
        path: '/car-function/function-lists',
        name: 'Function Lists',
        component: () => import('@/views/car-function/FunctionLists.vue'),
      },
      {
        path: '/car-function/car-management/add-car',
        name: 'Add New Car',
        component: () =>
          import('@/views/car-function/car-management/AddCar.vue'),
      },
      {
        path: '/car-function/car-management/edit-car/:id',
        name: 'Edit Car',
        component: () =>
          import('@/views/car-function/car-management/EditCar.vue'),
      },
      {
        path: '/car-function/car-management/manage-type',
        name: 'Manage Type',
        component: () =>
          import('@/views/car-function/car-management/ManageType.vue'),
      },
      {
        path: '/car-function/car-management/manage-tag',
        name: 'Manage Tag',
        component: () =>
          import('@/views/car-function/car-management/ManageTag.vue'),
      },
      {
        path: '/car-function/car-management/manage-brand',
        name: 'Manage Brand',
        component: () =>
          import('@/views/car-function/car-management/ManageBrand.vue'),
      },
      {
        path: '/car-function/car-management/manage-model',
        name: 'Manage Model',
        component: () =>
          import('@/views/car-function/car-management/ManageModel.vue'),
      },
      {
        path: '/car-function/car-management/manage-sub-model',
        name: 'Manage Sub Model',
        component: () =>
          import('@/views/car-function/car-management/ManageSubModel.vue'),
      },
      {
        path: '/car-function/car-management/manage-color',
        name: 'Manage Color',
        component: () =>
          import('@/views/car-function/car-management/ManageColor.vue'),
      },

      // import excel
      {
        path: '/import-excel/car',
        name: 'Import Car By Excel',
        component: () =>
          import('@/views/car-function/import-excel/ImportCar.vue'),
      },
      {
        path: '/import-excel/function',
        name: 'Import Function By Excel',
        component: () =>
          import('@/views/car-function/import-excel/ImportFunction.vue'),
      },
      // node
      {
        path: '/location/node-province',
        name: 'Node Province',
        component: () => import('@/views/location/NodeProvince.vue'),
      },
      {
        path: '/location/add-node',
        name: 'Add New Node',
        component: () => import('@/views/location/AddNode.vue'),
      },
      {
        path: '/location/node-list',
        name: 'Node Lists',
        component: () => import('@/views/location/NodeLists.vue'),
      },
      {
        path: '/location/node/:id',
        name: 'Node Lists Wildcard',
        component: () => import('@/views/location/NodeListsWildcard.vue'),
      },
      // user
      {
        path: '/user-management',
        name: 'User Management',
        component: () => import('@/views/apps/UserManagement.vue'),
        meta: { roles: [UserRoles.SUPER_ADMIN] },
      },
      // Chat
      {
        path: '/chat',
        name: 'Chat',
        component: () => import('@/views/apps/chat/Chat.vue'),
      },
      {
        path: '/packages',
        name: 'packageList',
        component: () => import('@/views/package/PackageLists.vue'),
      },
      {
        path: '/packages/new',
        name: 'newPackageDetail',
        component: () => import('@/views/package/PackageDetail.vue'),
      },
      {
        path: '/packages/:id',
        name: 'editPackageDetail',
        component: () => import('@/views/package/PackageDetail.vue'),
      },
      {
        path: '/stores',
        name: 'storeList',
        component: () => import('@/views/partner-store/PartnerStoreList.vue'),
      },
      {
        path: '/stores/:id',
        name: 'storeDetail',
        component: () =>
          import('@/views/partner-store/PartnerStoreDetails.vue'),
      },
      {
        path: '/promotions',
        name: 'promotionList',
        component: () => import('@/views/promotions/PromotionList.vue'),
      },
      {
        path: '/promotions/new',
        name: 'addPromotion',
        component: () => import('@/views/promotions/PromotionAddEdit.vue'),
      },
      {
        path: '/promotions/:promotionId',
        name: 'editPromotion',
        component: () => import('@/views/promotions/PromotionAddEdit.vue'),
      },
      {
        path: '/promotions/:promotionId/coupon',
        name: 'couponList',
        component: () => import('@/views/promotions/CouponList.vue'),
      },
      {
        path: '/promotion-banners',
        name: 'promotionBannerList',
        component: () =>
          import('@/views/promotion-banner/PromotionBannerList.vue'),
      },
      {
        path: '/promotion-banners/new',
        name: 'addPromotionBanner',
        component: () =>
          import('@/views/promotion-banner/PromotionBannerAddEdit.vue'),
      },
      {
        path: '/promotion-banners/:id',
        name: 'editPromotionBanner',
        component: () =>
          import('@/views/promotion-banner/PromotionBannerAddEdit.vue'),
      },
      {
        path: '/third-party',
        name: 'thirdPartyList',
        component: () => import('@/views/third-party/ThirdPartyList.vue'),
      },
      {
        path: '/third-party/new',
        name: 'newThirdParty',
        component: () => import('@/views/third-party/ThirdPartyAddEdit.vue'),
      },
      {
        path: '/third-party/:id',
        name: 'editThirdParty',
        component: () => import('@/views/third-party/ThirdPartyAddEdit.vue'),
      },

      // {
      //   path: '/builder',
      //   name: 'builder',
      //   component: () => import('@/views/Builder.vue'),
      // },
      // {
      //   path: '/crafted/pages/profile',
      //   name: 'profile',
      //   component: () => import('@/components/page-layouts/Profile.vue'),
      //   children: [
      //     {
      //       path: 'overview',
      //       name: 'profile-overview',
      //       component: () =>
      //         import('@/views/crafted/pages/profile/Overview.vue'),
      //     },
      //     {
      //       path: 'projects',
      //       name: 'profile-projects',
      //       component: () =>
      //         import('@/views/crafted/pages/profile/Projects.vue'),
      //     },
      //     {
      //       path: 'campaigns',
      //       name: 'profile-campaigns',
      //       component: () =>
      //         import('@/views/crafted/pages/profile/Campaigns.vue'),
      //     },
      //     {
      //       path: 'documents',
      //       name: 'profile-documents',
      //       component: () =>
      //         import('@/views/crafted/pages/profile/Documents.vue'),
      //     },
      //     {
      //       path: 'connections',
      //       name: 'profile-connections',
      //       component: () =>
      //         import('@/views/crafted/pages/profile/Connections.vue'),
      //     },
      //     {
      //       path: 'activity',
      //       name: 'profile-activity',
      //       component: () =>
      //         import('@/views/crafted/pages/profile/Activity.vue'),
      //     },
      //   ],
      // },
      // {
      //   path: '/crafted/pages/wizards/horizontal',
      //   name: 'horizontal-wizard',
      //   component: () => import('@/views/crafted/pages/wizards/Horizontal.vue'),
      // },
      // {
      //   path: '/crafted/pages/wizards/vertical',
      //   name: 'vertical-wizard',
      //   component: () => import('@/views/crafted/pages/wizards/Vertical.vue'),
      // },
      // {
      //   path: '/crafted/account',
      //   name: 'account',
      //   component: () => import('@/views/crafted/account/Account.vue'),
      //   children: [
      //     {
      //       path: 'overview',
      //       name: 'account-overview',
      //       component: () => import('@/views/crafted/account/Overview.vue'),
      //     },
      //     {
      //       path: 'settings',
      //       name: 'account-settings',
      //       component: () => import('@/views/crafted/account/Settings.vue'),
      //     },
      //   ],
      // },
      // {
      //   path: '/apps/customers/getting-started',
      //   name: 'apps-customers-getting-started',
      //   component: () => import('@/views/apps/customers/GettingStarted.vue'),
      // },
      // {
      //   path: '/apps/customers/customers-listing',
      //   name: 'apps-customers-listing',
      //   component: () => import('@/views/apps/customers/CustomersListing.vue'),
      // },
      // {
      //   path: '/apps/customers/customer-details',
      //   name: 'apps-customers-details',
      //   component: () => import('@/views/apps/customers/CustomerDetails.vue'),
      // },
      // {
      //   path: '/subscriptions/getting-started',
      //   name: 'subscriptions-getting-started',
      //   component: () =>
      //     import('@/views/apps/subscriptions/GettingStarted.vue'),
      // },
      // {
      //   path: '/subscriptions/subscription-list',
      //   name: 'subscriptions-subscription-list',
      //   component: () =>
      //     import('@/views/apps/subscriptions/SubscriptionList.vue'),
      // },
      // {
      //   path: '/subscriptions/add-subscription',
      //   name: 'subscriptions-add-subscription',
      //   component: () =>
      //     import('@/views/apps/subscriptions/AddSubscription.vue'),
      // },
      // {
      //   path: '/subscriptions/view-subscription',
      //   name: 'subscriptions-view-subscription',
      //   component: () =>
      //     import('@/views/apps/subscriptions/ViewSubscription.vue'),
      // },
      // {
      //   path: '/apps/calendar',
      //   name: 'apps-calendar',
      //   component: () => import('@/views/apps/Calendar.vue'),
      // },
      // {
      //   path: '/apps/chat/group-chat',
      //   name: 'apps-group-chat',
      //   component: () => import('@/views/apps/chat/Chat.vue'),
      // },
      // {
      //   path: '/apps/chat/drawer-chat',
      //   name: 'apps-drawer-chat',
      //   component: () => import('@/views/apps/chat/DrawerChat.vue'),
      // },
      // {
      //   path: '/crafted/modals/general/invite-friends',
      //   name: 'modals-general-invite-friends',
      //   component: () =>
      //     import('@/views/crafted/modals/general/InviteFriends.vue'),
      // },
      // {
      //   path: '/crafted/modals/general/view-user',
      //   name: 'modals-general-view-user',
      //   component: () => import('@/views/crafted/modals/general/ViewUsers.vue'),
      // },
      // {
      //   path: '/crafted/modals/general/upgrade-plan',
      //   name: 'modals-general-upgrade-plan',
      //   component: () =>
      //     import('@/views/crafted/modals/general/UpgradePlan.vue'),
      // },
      // {
      //   path: '/crafted/modals/general/share-and-earn',
      //   name: 'modals-general-share-and-earn',
      //   component: () =>
      //     import('@/views/crafted/modals/general/ShareAndEarn.vue'),
      // },
      // {
      //   path: '/crafted/modals/forms/new-target',
      //   name: 'modals-forms-new-target',
      //   component: () => import('@/views/crafted/modals/forms/NewTarget.vue'),
      // },
      // {
      //   path: '/crafted/modals/forms/new-card',
      //   name: 'modals-forms-new-card',
      //   component: () => import('@/views/crafted/modals/forms/NewCard.vue'),
      // },
      // {
      //   path: '/crafted/modals/forms/new-address',
      //   name: 'modals-forms-new-address',
      //   component: () => import('@/views/crafted/modals/forms/NewAddress.vue'),
      // },
      // {
      //   path: '/crafted/modals/forms/create-api-key',
      //   name: 'modals-forms-create-api-key',
      //   component: () =>
      //     import('@/views/crafted/modals/forms/CreateApiKey.vue'),
      // },
      // {
      //   path: '/crafted/modals/wizards/two-factor-auth',
      //   name: 'modals-wizards-two-factor-auth',
      //   component: () =>
      //     import('@/views/crafted/modals/wizards/TwoFactorAuth.vue'),
      // },
      // {
      //   path: '/crafted/modals/wizards/create-app',
      //   name: 'modals-wizards-create-app',
      //   component: () => import('@/views/crafted/modals/wizards/CreateApp.vue'),
      // },
      // {
      //   path: '/crafted/modals/wizards/create-account',
      //   name: 'modals-wizards-create-account',
      //   component: () =>
      //     import('@/views/crafted/modals/wizards/CreateAccount.vue'),
      // },
      // {
      //   path: '/crafted/widgets/lists',
      //   name: 'widgets-list',
      //   component: () => import('@/views/crafted/widgets/Lists.vue'),
      // },
      // {
      //   path: '/crafted/widgets/statistics',
      //   name: 'widgets-statistics',
      //   component: () => import('@/views/crafted/widgets/Statistics.vue'),
      // },
      // {
      //   path: '/crafted/widgets/charts',
      //   name: 'widgets-charts',
      //   component: () => import('@/views/crafted/widgets/Charts.vue'),
      // },
      // {
      //   path: '/crafted/widgets/mixed',
      //   name: 'widgets-mixed',
      //   component: () => import('@/views/crafted/widgets/Mixed.vue'),
      // },
      // {
      //   path: '/crafted/widgets/tables',
      //   name: 'widgets-tables',
      //   component: () => import('@/views/crafted/widgets/Tables.vue'),
      // },
      // {
      //   path: '/crafted/widgets/feeds',
      //   name: 'widgets-feeds',
      //   component: () => import('@/views/crafted/widgets/Feeds.vue'),
      // },
    ],
  },
  {
    path: '/',
    component: () => import('@/components/page-layouts/Auth.vue'),
    children: [
      {
        path: '/sign-in',
        name: 'sign-in',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/SignIn.vue'),
      },
      {
        path: '/sign-up',
        name: 'sign-up',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/SignUp.vue'),
      },
      {
        path: '/password-reset',
        name: 'password-reset',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/PasswordReset.vue'),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/crafted/authentication/Error404.vue'),
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@/views/crafted/authentication/Error500.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(authGuards);

export default router;
