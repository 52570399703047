import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export enum Actions {
  REFETCH_PACKAGE = `PackageModule/refetchPackages`,
}

export enum Getters {
  IS_LOADING = `PackageModule/isLoading`,
  PACKAGES = `PackageModule/getPackages`,
  TOTAL = `PackageModule/getTotal`,
}

enum Mutations {
  SET_PACKAGE = 'setPackage',
  LOADING = 'setLoading',
}

export interface Package {
  id: string;
  commission: number;
  createdAt: string;
  deleted: false;
  name: string;
  updatedAt: string;
}

export interface PackageInfo {
  packages: Package[];
  isLoading: boolean;
  total: number;
  rowsPerPage: number;
  currentPage: number;
}

@Module
export default class PackageModule extends VuexModule implements PackageInfo {
  packages = [] as Package[];
  loading = false;
  total = 0;
  rowsPerPage = 99999;
  currentPage = 1;

  get isLoading(): boolean {
    return this.loading;
  }

  get getPackages(): Package[] {
    return this.packages;
  }

  get getTotal(): number {
    return this.total;
  }

  @Mutation
  [Mutations.LOADING]() {
    this.loading = true;
  }

  @Mutation
  [Mutations.SET_PACKAGE](payload: any) {
    const { datas, total } = payload;
    this.total = total;
    this.packages = datas;
    this.loading = false;
  }

  @Action
  [Actions.REFETCH_PACKAGE.split('/')[1]]() {
    const condition = {
      page: `${this.currentPage}`,
      pageSize: `${this.rowsPerPage}`,
    };

    this.context.commit(Mutations.LOADING);
    return ApiService.get(`/package?${new URLSearchParams(condition)}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PACKAGE, data);
      })
      .catch(({ response }) => {
        return Promise.reject(response.data.message);
      });
  }
}
