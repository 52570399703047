import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export enum Actions {
  FETCH_ALL_BRAND = `BrandModule/fetchAllBrands`,
}

export enum Getters {
  IS_LOADING = `BrandModule/isLoading`,
  BRANDS = `BrandModule/getBrands`,
  TOTAL = `BrandModule/getTotal`,
}

enum Mutations {
  SET_BRAND = 'setBrand',
  LOADING = 'setLoading',
}

export interface Brand {
  id: string;
  name: string;
  models: string[];
}

export interface BrandInfo {
  brands: Brand[];
  isLoading: boolean;
  total: number;
  rowsPerPage: number;
  currentPage: number;
}

@Module
export default class BrandModule extends VuexModule implements BrandInfo {
  brands = [] as Brand[];
  loading = false;
  total = 0;
  rowsPerPage = 100;
  currentPage = 1;

  get isLoading(): boolean {
    return this.loading;
  }

  get getBrands(): Brand[] {
    return this.brands;
  }

  get getTotal(): number {
    return this.total;
  }

  @Mutation
  [Mutations.LOADING]() {
    this.loading = true;
  }

  @Mutation
  [Mutations.SET_BRAND](payload: any) {
    const { datas, total } = payload;
    this.total = total;
    this.brands = datas;
    this.loading = false;
  }

  @Action
  async [Actions.FETCH_ALL_BRAND.split('/')[1]]() {
    const condition = {
      page: `${this.currentPage}`,
      pageSize: `${this.rowsPerPage}`,
    };

    this.context.commit(Mutations.LOADING);
    try {
      const { data } = await ApiService.get(
        `car-brand?${new URLSearchParams(condition)}`
      );
      this.context.commit(Mutations.SET_BRAND, data);
    } catch ({ response }) {
      return await Promise.reject(response);
    }
  }
}
