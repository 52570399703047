import ApiService from '@/core/services/ApiService';
import { AxiosRequestConfig } from 'axios';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

//THIRD_PARTY replace this
//ThirdParty replace this
//thirdParty replace this

export enum Actions {
  FETCH_THIRD_PARTY = `ThirdPartyModule/fetchAllThirdParties`,
  FIND_BY_ID = `ThirdPartyModule/findThirdPartyById`,
  CREATE_THIRD_PARTY = `ThirdPartyModule/createThirdParty`,
  UPDATE_THIRD_PARTY = `ThirdPartyModule/updateThirdParty`,
  UPDATE_THIRD_PARTY_STATUS = `ThirdPartyModule/updateThirdPartyStatus`,
  REVOKE_TOKEN = `ThirdPartyModule/revokeToken`,
}

export enum Getters {
  IS_LOADING = `ThirdPartyModule/isLoading`,

  THIRD_PARTIES = `ThirdPartyModule/getThirdParties`,
  TOTAL = `ThirdPartyModule/getTotal`,
}

enum Mutations {
  SET_THIRD_PARTY = 'setThirdParty',
  LOADING = 'setLoading',
  LOADED = 'setLoaded',
}

export interface ThirdParty {
  id: string;
  name: string;
  description: string;
  isActive: boolean;
  callback: string;
  apiKey: string;
  secretKey: string;
}

export interface ThirdPartyPayload {
  name: string | null;
  description: string | null;
  isActive: boolean;
  callback: string | null;
  apiKey?: string;
  secretKey?: string;
  username: string | null;
  password: string | null;
  confirmPassword: string | null;
}

export interface ThirdPartyInfo {
  thirdParties: ThirdParty[];
  isLoading: boolean;
  total: number;
  rowsPerPage: number;
  currentPage: number;
}

@Module
export default class ThirdPartyModule
  extends VuexModule
  implements ThirdPartyInfo
{
  thirdParties: ThirdParty[] = [];
  loading = false;
  total = 0;
  rowsPerPage = 100;
  currentPage = 1;

  get isLoading(): boolean {
    return this.loading;
  }

  get getThirdParties(): ThirdParty[] {
    return this.thirdParties;
  }

  get getTotal(): number {
    return this.total;
  }

  @Mutation
  [Mutations.LOADING]() {
    this.loading = true;
  }

  @Mutation
  [Mutations.LOADED]() {
    this.loading = true;
  }

  @Mutation
  [Mutations.SET_THIRD_PARTY](payload: any) {
    const { datas, total } = payload;
    this.total = total;
    this.thirdParties = datas;
    this.loading = false;
  }

  @Action
  async [Actions.FETCH_THIRD_PARTY.split('/')[1]]() {
    const condition = {
      page: `${this.currentPage}`,
      pageSize: `${this.rowsPerPage}`,
    };

    this.context.commit(Mutations.LOADING);
    try {
      const { data } = await ApiService.get(
        `third-party?${new URLSearchParams(condition)}`
      );

      this.context.commit(Mutations.LOADED);
      return data;
    } catch ({ response }) {
      // return await Promise.reject(response);
      const { data } = await ApiService.get(
        `third-party/63f31b15f8286ebe61423924?${new URLSearchParams(condition)}`
        //63f31b15f8286ebe61423924
        //6352a27802916d2da74ef14a
      );

      this.context.commit(Mutations.LOADED);
      return { datas: [data.data], total: 1 };
    }
  }

  @Action
  async [Actions.FIND_BY_ID.split('/')[1]](id = 0): Promise<ThirdParty> {
    this.context.commit(Mutations.LOADING);
    const { data } = await ApiService.get(`third-party/${id}`);
    this.context.commit(Mutations.LOADED);
    return data.data;
  }

  @Action
  async [Actions.CREATE_THIRD_PARTY.split('/')[1]](payload: ThirdPartyPayload) {
    const body = { ...payload };

    try {
      const { data } = await ApiService.post('third-party', body);
      return data;
    } catch ({ response }) {
      return await Promise.reject(response);
    }
  }

  @Action
  async [Actions.UPDATE_THIRD_PARTY.split('/')[1]]({
    id,
    payload,
  }: {
    id: string;
    payload: ThirdPartyPayload;
  }) {
    try {
      const { data } = await ApiService.put(`third-party/${id}`, {
        ...payload,
      } as AxiosRequestConfig);
      return data;
    } catch ({ response }) {
      return await Promise.reject(response);
    }
  }

  @Action
  async [Actions.UPDATE_THIRD_PARTY_STATUS.split('/')[1]]({
    id,
    payload,
  }: {
    id: string;
    payload: ThirdPartyPayload;
  }): Promise<ThirdParty> {
    this.context.commit(Mutations.LOADING);
    const { data } = await ApiService.put(`third-party/${id}`, {
      ...payload,
    } as AxiosRequestConfig);
    this.context.commit(Mutations.LOADED);
    return data.data;
  }

  @Action
  async [Actions.REVOKE_TOKEN.split('/')[1]](id: string) {
    try {
      this.context.commit(Mutations.LOADING);
      const { data } = await ApiService.put(`third-party/${id}/revoke`, {});
      this.context.commit(Mutations.LOADED);
      return data.data;
    } catch ({ response }) {
      return await Promise.reject(response);
    }
  }
}
