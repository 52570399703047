import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
// import { AxiosRequestConfig } from 'axios';

export interface User {
  id: string;
  username: string;
  role: string;
  accessToken: string;
  refreshToken: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = JSON.parse(localStorage.getItem('user') || '{}') as User;
  isAuthenticated = !!JwtService.getTokens().refreshToken;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get Authentication errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH_ERROR](error) {
    this.errors = error;
    this.user = {} as User;
    this.isAuthenticated = false;
    JwtService.destroyTokens();
    localStorage.removeItem('user');
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.user = user;
    this.errors = [];
    this.isAuthenticated = true;
    JwtService.saveToken('accessToken', this.user.accessToken, 100);
    JwtService.saveToken('refreshToken', this.user.refreshToken, 3600);
    localStorage.setItem(
      'user',
      JSON.stringify({ username: this.user.username, role: this.user.role })
    );
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  // @Mutation
  // [Mutations.SET_PASSWORD](password) {
  //   this.user.password = password;
  // }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.user = {} as User;
    this.errors = [];
    this.isAuthenticated = false;
    JwtService.destroyTokens();
    localStorage.removeItem('user');
  }

  @Action
  [Actions.LOGIN](credentials) {
    const params = { ...credentials };

    return ApiService.post('auth/login', params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_AUTH_ERROR, response.data.message);
        return Promise.reject(response.data.message);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  // @Action
  // [Actions.REGISTER](credentials) {
  //   return ApiService.post('register', credentials)
  //     .then(({ data }) => {
  //       this.context.commit(Mutations.SET_AUTH, data);
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //     });
  // }

  // @Action
  // [Actions.FORGOT_PASSWORD](payload) {
  //   const params = {
  //     params: {
  //       ...payload,
  //     },
  //   };
  //   return ApiService.query('forgot_password', params)
  //     .then(({ data }) => {
  //       this.context.commit(Mutations.SET_AUTH, data);
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //     });
  // }

  // @Action
  // [Actions.VERIFY_AUTH]() {
  //   const { accessToken, refreshToken } = JwtService.getTokens();

  //   if (accessToken) {
  //     return;
  //   }

  //   if (refreshToken) {
  //     ApiService.post('auth/refresh-token', {
  //       refreshToken,
  //     } as AxiosRequestConfig).then(({ data }) => {
  //       this.context.commit(Mutations.SET_AUTH, data);
  //     });
  //   } else {
  //     this.context.commit(Mutations.PURGE_AUTH);
  //   }
  // }

  // @Action
  // [Actions.UPDATE_USER](payload) {
  //   ApiService.setHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post("update_user", payload)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_USER, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //         reject();
  //       });
  //   });
  // }
}
