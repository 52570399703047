import ApiService from '@/core/services/ApiService';
import { isUndefined, omitBy } from 'lodash';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export enum Actions {
  FETCH_ALL_SERVICE_POINT = `ServicePointModule/fetchAllServicePoints`,
}

export enum Getters {
  IS_LOADING = `ServicePointModule/isLoading`,
  SERVICE_POINTS = `ServicePointModule/getServicePoints`,
  TOTAL = `ServicePointModule/getTotal`,
}

enum Mutations {
  SET_SERVICE_POINT = 'setServicePoint',
  LOADING = 'setLoading',
}

export interface Province {
  name: string;
  id: string;
}

export interface ServicePoint {
  id: string;
  isEnable: boolean;
  latitude: number;
  longitude: number;
  name: string;
  province: Province;
  updatedAt: string;
}

export interface ServicePointInfo {
  servicePoints: ServicePoint[];
  isLoading: boolean;
  total: number;
  rowsPerPage: number;
  currentPage: number;
}

export interface FetchServicePointFilter {
  province: string;
}

@Module
export default class ServicePointModule
  extends VuexModule
  implements ServicePointInfo
{
  servicePoints = [] as ServicePoint[];
  loading = false;
  total = 0;
  rowsPerPage = 99999;
  currentPage = 1;

  get isLoading(): boolean {
    return this.loading;
  }

  get getServicePoints(): ServicePoint[] {
    return this.servicePoints;
  }

  get getTotal(): number {
    return this.total;
  }

  @Mutation
  [Mutations.LOADING]() {
    this.loading = true;
  }

  @Mutation
  [Mutations.SET_SERVICE_POINT](payload: any) {
    const { datas, total } = payload;
    this.total = total;
    this.servicePoints = datas;
    this.loading = false;
  }

  @Action
  [Actions.FETCH_ALL_SERVICE_POINT.split('/')[1]](
    filter: FetchServicePointFilter
  ) {
    const { province } = filter || {};
    const condition = omitBy(
      {
        page: `${this.currentPage}`,
        pageSize: `${this.rowsPerPage}`,
        province,
      },
      isUndefined
    );

    this.context.commit(Mutations.LOADING);
    return ApiService.get(
      `location/service-point?${new URLSearchParams(condition)}`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SERVICE_POINT, data);
      })
      .catch(({ response }) => {
        return Promise.reject(response.data.message);
      });
  }
}
