import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

//CAR_MODEL replace this
//CarModel replace this
//carModel replace this

export enum Actions {
  FETCH_ALL_CAR_MODEL = `CarModelModule/fetchAllCarModels`,
}

export enum Getters {
  IS_LOADING = `CarModelModule/isLoading`,
  CAR_MODELS = `CarModelModule/getCarModels`,
  TOTAL = `CarModelModule/getTotal`,
}

enum Mutations {
  SET_CAR_MODEL = 'setCarModel',
  LOADING = 'setLoading',
}

export interface CarModel {
  id: string;
}

export interface CarModelInfo {
  carModels: CarModel[];
  isLoading: boolean;
  total: number;
  rowsPerPage: number;
  currentPage: number;
}

@Module
export default class CarModelModule extends VuexModule implements CarModelInfo {
  carModels = [] as CarModel[];
  loading = false;
  total = 0;
  rowsPerPage = 100;
  currentPage = 1;

  get isLoading(): boolean {
    return this.loading;
  }

  get getCarModels(): CarModel[] {
    return this.carModels;
  }

  get getTotal(): number {
    return this.total;
  }

  @Mutation
  [Mutations.LOADING]() {
    this.loading = true;
  }

  @Mutation
  [Mutations.SET_CAR_MODEL](payload: any) {
    const { datas, total } = payload;
    this.total = total;
    this.carModels = datas;
    this.loading = false;
  }

  @Action
  async [Actions.FETCH_ALL_CAR_MODEL.split('/')[1]]() {
    const condition = {
      page: `${this.currentPage}`,
      pageSize: `${this.rowsPerPage}`,
    };

    this.context.commit(Mutations.LOADING);
    try {
      const { data } = await ApiService.get(
        `car-brand-model?${new URLSearchParams(condition)}`
      );
      this.context.commit(Mutations.SET_CAR_MODEL, data);
    } catch ({ response }) {
      return await Promise.reject(response);
    }
  }
}
