import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import AuthModule from '@/store/modules/AuthModule';
import BodyModule from '@/store/modules/BodyModule';
import BreadcrumbsModule from '@/store/modules/BreadcrumbsModule';
import ConfigModule from '@/store/modules/ConfigModule';
import PackageModule from '@/store/modules/PackageModule';
import StoreModule from '@/store/modules/StoreModule';
import ServicePointModule from './modules/ServicePointModule';
import ProvinceModule from './modules/ProvinceModule';
import BrandModule from './modules/BrandModule';
import SeriesModule from './modules/SeriesModule';
import CarModelModule from './modules/CarModelModule';
import PromotionModule from './modules/PromotionModule';
import CouponModule from './modules/CouponModule';
import ThirdPartyModule from './modules/ThirdPartyModule';
import PromotionBannerModule from './modules/PromotionBannerModule';
import ResourceModule from './modules/ResourceModule';

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    PackageModule: {
      ...PackageModule,
      namespaced: true,
    },
    StoreModule: {
      ...StoreModule,
      namespaced: true,
    },
    ServicePointModule: {
      ...ServicePointModule,
      namespaced: true,
    },
    ProvinceModule: {
      ...ProvinceModule,
      namespaced: true,
    },
    BrandModule: {
      ...BrandModule,
      namespaced: true,
    },
    SeriesModule: {
      ...SeriesModule,
      namespaced: true,
    },
    CarModelModule: {
      ...CarModelModule,
      namespaced: true,
    },
    CouponModule: {
      ...CouponModule,
      namespaced: true,
    },
    ThirdPartyModule: {
      ...ThirdPartyModule,
      namespaced: true,
    },
    PromotionModule: {
      ...PromotionModule,
      namespaced: true,
    },
    PromotionBannerModule: {
      ...PromotionBannerModule,
      namespaced: true,
    },
    ResourceModule: {
      ...ResourceModule,
      namespaced: true,
    },
  },
});

export default store;
