import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export enum Actions {
  FETCH_ALL_SERIES = `SeriesModule/fetchAllSeries`,
}

export enum Getters {
  IS_LOADING = `SeriesModule/isLoading`,
  SERIES = `SeriesModule/getSeries`,
  TOTAL = `SeriesModule/getTotal`,
}

enum Mutations {
  SET_SERIES = 'setSeries',
  LOADING = 'setLoading',
}

export interface Series {
  id: string;
}

export interface SeriesInfo {
  series: Series[];
  isLoading: boolean;
  total: number;
  rowsPerPage: number;
  currentPage: number;
}

@Module
export default class SeriesModule extends VuexModule implements SeriesInfo {
  series = [] as Series[];
  loading = false;
  total = 0;
  rowsPerPage = 100;
  currentPage = 1;

  get isLoading(): boolean {
    return this.loading;
  }

  get getSeries(): Series[] {
    return this.series;
  }

  get getTotal(): number {
    return this.total;
  }

  @Mutation
  [Mutations.LOADING]() {
    this.loading = true;
  }

  @Mutation
  [Mutations.SET_SERIES](payload: any) {
    const { datas, total } = payload;
    this.total = total;
    this.series = datas;
    this.loading = false;
  }

  @Action
  async [Actions.FETCH_ALL_SERIES.split('/')[1]]() {
    const condition = {
      page: `${this.currentPage}`,
      pageSize: `${this.rowsPerPage}`,
    };

    this.context.commit(Mutations.LOADING);
    try {
      const { data } = await ApiService.get(
        `car-brand-series?${new URLSearchParams(condition)}`
      );
      this.context.commit(Mutations.SET_SERIES, data);
    } catch ({ response }) {
      return await Promise.reject(response);
    }
  }
}
