import Cookies from 'js-cookie';
import ApiService from '@/core/services/ApiService';
import store from '@/store';
import { Mutations } from '@/store/enums/StoreEnums';

type Token = 'accessToken' | 'refreshToken';

/**
 * @description get token form cookies
 */
export const getTokens = (): {
  accessToken: string | null;
  refreshToken: string | null;
} => {
  const accessToken: string = Cookies.get('accessToken');
  const refreshToken: string = Cookies.get('refreshToken');

  return { accessToken, refreshToken };
};

/**
 * @description save token into cookies
 * @param token: string
 */
export const saveToken = (key: Token, token: string, minutes = 5): void => {
  const expires = new Date(new Date().getTime() + minutes * 60 * 1000);

  Cookies.set(key, token, {
    expires,
    secure: true,
    sameSite: 'strict',
  });
};

/**
 * @description remove token from cookies
 */
export const destroyTokens = (): void => {
  Cookies.remove('accessToken');
  Cookies.remove('refreshToken');
};

export const refreshTokens = async () => {
  const { refreshToken }: any = getTokens();
  const { data } = await ApiService.post('auth/refresh-token', {
    refreshToken,
  });
  store.commit(Mutations.SET_AUTH, data);
};

export default { getTokens, saveToken, destroyTokens, refreshTokens };
