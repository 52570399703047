import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export enum Actions {
  FETCH_ALL_PROVINCE = `ProvinceModule/fetchAllProvinces`,
}

export enum Getters {
  IS_LOADING = `ProvinceModule/isLoading`,
  PROVINCES = `ProvinceModule/getProvinces`,
  TOTAL = `ProvinceModule/getTotal`,
}

enum Mutations {
  SET_PROVINCE = 'setProvince',
  LOADING = 'setLoading',
}

interface District {
  id: string;
  name: string;
}

export interface Province {
  id: string;
  districts: District[];
  name: string;
}

export interface ProvinceInfo {
  provinces: Province[];
  isLoading: boolean;
  total: number;
  rowsPerPage: number;
  currentPage: number;
}

@Module
export default class ProvinceModule extends VuexModule implements ProvinceInfo {
  provinces = [] as Province[];
  loading = false;
  total = 0;
  rowsPerPage = 100;
  currentPage = 1;

  get isLoading(): boolean {
    return this.loading;
  }

  get getProvinces(): Province[] {
    return this.provinces;
  }

  get getTotal(): number {
    return this.total;
  }

  @Mutation
  [Mutations.LOADING]() {
    this.loading = true;
  }

  @Mutation
  [Mutations.SET_PROVINCE](payload: any) {
    const { datas, total } = payload;
    this.total = total;
    this.provinces = datas;
    this.loading = false;
  }

  @Action
  [Actions.FETCH_ALL_PROVINCE.split('/')[1]]() {
    const condition = {
      page: `${this.currentPage}`,
      pageSize: `${this.rowsPerPage}`,
    };

    this.context.commit(Mutations.LOADING);
    return ApiService.get(`location/province?${new URLSearchParams(condition)}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROVINCE, data);
      })
      .catch(({ response }) => {
        return Promise.reject(response.data.message);
      });
  }
}
