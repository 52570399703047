export const ServicesTypes = [
  {
    name: 'Rent Car Per Day',
    value: 'rentCarPerDay',
  },
  {
    name: 'Rent Car Per Month',
    value: 'rentCarPerMonth',
  },
  {
    name: 'Rent Car With Driver',
    value: 'rentCarWithDriver',
  },
];

export const reportStatus = [
  { name: 'Initial', value: 'Initial' },
  { name: 'Reject', value: 'Reject' },
  { name: 'Done', value: 'Done' },
];

export enum AmountTypeEnum {
  FIXED = 'Fixed',
  PERCENTAGE = 'Percentage',
}

export enum PromotionTypeEnum {
  FIX = 'Fix',
  PREFIX = 'Prefix',
}

export enum PaymentTypes {
  QR = 'QR',
  CREDIT = 'Credit',
}
