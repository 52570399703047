import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export enum Actions {
  REFETCH_STORE = `StoreModule/refetchStores`,
}

export enum Getters {
  IS_LOADING = `StoreModule/isLoading`,
  STORES = `StoreModule/getStores`,
  TOTAL = `StoreModule/getTotal`,
}

enum Mutations {
  SET_STORE = 'setStore',
  LOADING = 'setLoading',
}

interface Image {
  image: string;
}

interface Location {
  name: string;
  coordinates: number[];
}

interface Owner {
  id: string;
}

interface Package {
  name: string;
  commission: number;
  id: string;
}

interface Staff {
  partner: string;
  role: string;
  state: string;
  _id: string;
}

export interface Store {
  id: string;
  address: string;
  carImages: Image[];
  createdAt: string;
  deleted: false;
  description: string;
  district: string;
  email: string;
  image: string;
  location: Location;
  name: string;
  organization: string;
  owner: Owner;
  package: Package;
  phone: string;
  postNumber: string;
  province: string;
  staffs: Staff[];
  subDistrict: string;
  updatedAt: string;
  vibeImages: Image[];
}

export interface StoreInfo {
  packages: Store[];
  isLoading: boolean;
  total: number;
  rowsPerPage: number;
  currentPage: number;
}

@Module
export default class StoreModule extends VuexModule implements StoreInfo {
  packages = [] as Store[];
  loading = false;
  total = 0;
  rowsPerPage = 99999;
  currentPage = 1;

  get isLoading(): boolean {
    return this.loading;
  }

  get getStores(): Store[] {
    return this.packages;
  }

  get getTotal(): number {
    return this.total;
  }

  @Mutation
  [Mutations.LOADING]() {
    this.loading = true;
  }

  @Mutation
  [Mutations.SET_STORE](payload: any) {
    const { datas, total } = payload;
    this.total = total;
    this.packages = datas;
    this.loading = false;
  }

  @Action
  [Actions.REFETCH_STORE.split('/')[1]]() {
    const condition = {
      page: `${this.currentPage}`,
      pageSize: `${this.rowsPerPage}`,
    };

    this.context.commit(Mutations.LOADING);
    return ApiService.get(`/store?${new URLSearchParams(condition)}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_STORE, data);
      })
      .catch(({ response }) => {
        return Promise.reject(response.data.message);
      });
  }
}
