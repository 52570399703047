import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export enum Actions {
  FETCH_ALL_COUPON = `CouponModule/fetchAllCoupons`,
  FETCH_COUPON = `CouponModule/fetchCoupons`,
}

export enum Getters {
  IS_LOADING = `CouponModule/isLoading`,
  COUPONS = `CouponModule/getCoupons`,
  TOTAL = `CouponModule/getTotal`,
}

enum Mutations {
  SET_COUPON = 'setCoupon',
  LOADING = 'setLoading',
}

export interface Coupon {
  id: string;
  quantity: number;
}

export interface CouponInfo {
  coupons: Coupon[];
  isLoading: boolean;
  total: number;
  rowsPerPage: number;
  currentPage: number;
}

@Module
export default class CouponModule extends VuexModule implements CouponInfo {
  coupons = [] as Coupon[];
  loading = false;
  total = 0;
  rowsPerPage = 100;
  currentPage = 1;

  get isLoading(): boolean {
    return this.loading;
  }

  get getCoupons(): Coupon[] {
    return this.coupons;
  }

  get getTotal(): number {
    return this.total;
  }

  @Mutation
  [Mutations.LOADING]() {
    this.loading = true;
  }

  @Mutation
  [Mutations.SET_COUPON](payload: any) {
    const { datas, total } = payload;
    this.total = total;
    this.coupons = datas;
    this.loading = false;
  }

  @Action
  async [Actions.FETCH_ALL_COUPON.split('/')[1]]() {
    const condition = {
      page: `${this.currentPage}`,
      pageSize: `${this.rowsPerPage}`,
    };

    this.context.commit(Mutations.LOADING);
    try {
      const { data } = await ApiService.get(
        `coupon?${new URLSearchParams(condition)}`
      );
      this.context.commit(Mutations.SET_COUPON, data);
    } catch ({ response }) {
      return await Promise.reject(response);
    }
  }

  @Action
  async [Actions.FETCH_COUPON.split('/')[1]]({ promotionId, page, pageSize }) {
    const condition = {
      page,
      pageSize,
    };
    this.context.commit(Mutations.LOADING);
    try {
      const { data } = await ApiService.get(
        `promotion/${promotionId}/coupon?${new URLSearchParams(condition)}`
      );
      this.context.commit(Mutations.SET_COUPON, data);

      return data;
    } catch ({ response }) {
      return await Promise.reject(response);
    }
  }
}
