import {
  AmountTypeEnum,
  PaymentTypes,
  ServicesTypes,
} from '@/core/data/constance';
import ApiService from '@/core/services/ApiService';
import { isUndefined, omitBy } from 'lodash';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

//PROMOTION replace this
//Promotion replace this
//promotion replace this

export enum Actions {
  FETCH_PROMOTION = `PromotionModule/fetchPromotions`,
  FETCH_ALL_PROMOTION = `PromotionModule/fetchAllPromotions`,
  CREATE_PROMOTION = `PromotionModule/createPromotion`,
  GET_PROMOTION_BY_ID = `PromotionModule/getPromotionById`,
  UPDATE_PROMOTION = `PromotionModule/updatePromotion`,
  DELETE_PROMOTION = `PromotionModule/deletePromotion`,
  MAP_PROMOTION_TO_PAYLOAD = `PromotionModule/promotionToPayload`,
  UPDATE_PROMOTION_STATUS = `PromotionModule/updatePromotionStatus`,
}

export enum Getters {
  IS_LOADING = `PromotionModule/isLoading`,
  PROMOTIONS = `PromotionModule/getPromotions`,
  TOTAL = `PromotionModule/getTotal`,
}

enum Mutations {
  SET_PROMOTION = 'setPromotion',
  LOADING = 'setLoading',
  LOADED = 'setLoaded',
}

export interface Period {
  start: number;
  end: number;
}

export interface Promotion {
  id?: string | null;
  name: string | null;
  description: string | null;
  amount: string | null;
  amountType?: AmountTypeEnum;
  isMinPurchase: boolean;
  minPurchase: string | null;
  isMaxDiscount: boolean;
  maxDiscount: string | null;
  isLimitPerUser: boolean;
  startDate: number;
  endDate: number;
  type: string | null; // fix type
  code: string | null;
  quantity: number | null; //amount use for...
  limitPerUser: string | null; // amount per user
  isCarPackage: boolean;
  carPackages?: string[]; // map from carPackagesOption
  isStore: boolean;
  stores?: string[];
  isPackage: boolean;
  packages?: string[];
  isServicePoint: boolean;
  servicePoints?: string[];
  isCustomCar: boolean;
  isBrands: true;
  brands?: string[];
  isModels: true;
  models?: string[];
  isSeries: true;
  series?: string[];
  isPeriod: boolean;
  periods?: Period[];
  paymentTypes?: string[];
  prefixCode?: string;
  isActive: boolean;
}

export interface PromotionPayload extends Promotion {
  carPackagesOption: { [key: string]: boolean };
  paymentTypesOption: { [key: string]: boolean };
  startDatePath?: string;
  startTimePath?: string;
  endDatePath?: string;
  endTimePath?: string;
}

export interface PromotionInfo {
  promotions: Promotion[];
  isLoading: boolean;
  total: number;
  rowsPerPage: number;
  currentPage: number;
}

@Module
export default class PromotionModule
  extends VuexModule
  implements PromotionInfo
{
  promotions = [] as Promotion[];
  loading = false;
  total = 0;
  rowsPerPage = 100;
  currentPage = 1;

  get isLoading(): boolean {
    return this.loading;
  }

  get getPromotions(): Promotion[] {
    return this.promotions;
  }

  get getTotal(): number {
    return this.total;
  }

  @Mutation
  [Mutations.LOADING]() {
    this.loading = true;
  }

  @Mutation
  [Mutations.LOADED]() {
    this.loading = false;
  }

  @Mutation
  [Mutations.SET_PROMOTION](payload: any) {
    const { datas, total } = payload;
    this.total = total;
    this.promotions = datas;
    this.loading = false;
  }

  @Action
  async [Actions.FETCH_ALL_PROMOTION.split('/')[1]]() {
    const condition = {
      page: `1`,
      pageSize: `99999`,
    };

    this.context.commit(Mutations.LOADING);
    const { data } = await ApiService.get(
      `promotion?${new URLSearchParams(condition)}`
    );
    this.context.commit(Mutations.SET_PROMOTION, data);
    return data.datas;
  }

  @Action
  async [Actions.FETCH_PROMOTION.split('/')[1]]() {
    const condition = {
      page: `${this.currentPage}`,
      pageSize: `${this.rowsPerPage}`,
    };

    this.context.commit(Mutations.LOADING);
    const { data } = await ApiService.get(
      `promotion?${new URLSearchParams(condition)}`
    );
    this.context.commit(Mutations.SET_PROMOTION, data);
    return data;
  }

  @Action
  async [Actions.CREATE_PROMOTION.split('/')[1]](
    payload: PromotionPayload
  ): Promise<Promotion> {
    this.context.commit(Mutations.LOADING);
    const { data } = await ApiService.post(
      `promotion`,
      omitBy({ ...payload }, isUndefined)
    );
    this.context.commit(Mutations.SET_PROMOTION, data);
    return data.data;
  }

  @Action
  async [Actions.UPDATE_PROMOTION.split('/')[1]]({
    id,
    payload,
  }: {
    id: string;
    payload: PromotionPayload;
  }): Promise<Promotion> {
    this.context.commit(Mutations.LOADING);
    const { data } = await ApiService.patch(`promotion/${id}`, {
      ...payload,
    });
    this.context.commit(Mutations.SET_PROMOTION, data);
    return data.data;
  }

  @Action
  async [Actions.UPDATE_PROMOTION_STATUS.split('/')[1]]({
    id,
    isActive,
  }: {
    id: string;
    isActive: boolean;
  }): Promise<Promotion> {
    this.context.commit(Mutations.LOADING);
    const { data } = await ApiService.patch(`promotion/${id}`, {
      isActive,
    });
    this.context.commit(Mutations.SET_PROMOTION, data);
    return data.data;
  }

  @Action
  async [Actions.GET_PROMOTION_BY_ID.split('/')[1]](
    id = 0
  ): Promise<Promotion> {
    this.context.commit(Mutations.LOADING);
    const { data } = await ApiService.get(`promotion/${id}`);
    this.context.commit(Mutations.SET_PROMOTION, data);
    return data.data;
  }

  @Action
  async [Actions.MAP_PROMOTION_TO_PAYLOAD.split('/')[1]](
    promotion: Promotion
  ): Promise<PromotionPayload> {
    return {
      ...promotion,
      carPackagesOption: {
        [ServicesTypes[0].value]:
          promotion.carPackages?.includes(ServicesTypes[0].value) || false,
        [ServicesTypes[1].value]:
          promotion.carPackages?.includes(ServicesTypes[1].value) || false,
        [ServicesTypes[2].value]:
          promotion.carPackages?.includes(ServicesTypes[2].value) || false,
      },
      paymentTypesOption: {
        [PaymentTypes.QR]:
          promotion.paymentTypes?.includes(PaymentTypes.QR) || false,
        [PaymentTypes.CREDIT]:
          promotion.paymentTypes?.includes(PaymentTypes.CREDIT) || false,
      },
      periods: promotion.periods?.map((period) => ({
        start: new Date(period.start).getTime(),
        end: new Date(period.end).getTime(),
      })),
      startDatePath: new Date(promotion.startDate).toISOString(),
      startTimePath: new Date(promotion.startDate).toISOString(),
      endDatePath: new Date(promotion.endDate).toISOString(),
      endTimePath: new Date(promotion.endDate).toISOString(),
      code: promotion.prefixCode || promotion.code,
      packages: promotion.packages || [],
      stores: promotion.stores || [],
      servicePoints: promotion.servicePoints || [],
      brands: promotion.brands || [],
      models: promotion.models || [],
      series: promotion.series || [],
      isBrands: true,
      isModels: true,
      isSeries: true,
      isLimitPerUser: true,
      isActive: promotion.isActive || false,
    };
  }
}
