import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

//RESOURCE replace this
//Resource replace this
//resource replace this

export enum Actions {
  GENERATE_UPLOAD_URL = `ResourceModule/generateUploadUrl`,
  UPLOAD_IMAGE = `ResourceModule/uploadImage`,
}

export enum Getters {
  IS_LOADING = `ResourceModule/isLoading`,
  RESOURCES = `ResourceModule/getResources`,
  TOTAL = `ResourceModule/getTotal`,
}

enum Mutations {
  LOADING = 'setLoading',
  LOADED = 'setLoaded',
}

export interface Resource {
  id: string;
}

export interface ResourceInfo {
  resources: Resource[];
  isLoading: boolean;
  total: number;
  rowsPerPage: number;
  currentPage: number;
}

@Module
export default class ResourceModule extends VuexModule implements ResourceInfo {
  resources = [] as Resource[];
  loading = false;
  total = 0;
  rowsPerPage = 100;
  currentPage = 1;

  get isLoading(): boolean {
    return this.loading;
  }

  get getResources(): Resource[] {
    return this.resources;
  }

  get getTotal(): number {
    return this.total;
  }

  @Mutation
  [Mutations.LOADING]() {
    this.loading = true;
  }

  @Mutation
  [Mutations.LOADED]() {
    this.loading = false;
  }

  @Action
  async [Actions.GENERATE_UPLOAD_URL.split('/')[1]]() {
    this.context.commit(Mutations.LOADING);
    try {
      const { data } = await ApiService.post(`resources/generate-upload-url`, {
        category: 'promotion',
        extension: 'png',
      });
      this.context.commit(Mutations.LOADED);
      return data.data;
    } catch ({ response }) {
      return await Promise.reject(response);
    }
  }
}
